<!--
  This component contains the Purchases Card, the Volume Bonus Card and the Completion Card
  For the NFIT User Profile it only shows the Welcome Card component
-->
<template>
  <div class="home">
    <CRow>
      <CCol xl="6" v-if="user.profileId === 30">
        <WelcomeCard :user="user" />
      </CCol>
      <CCol xl="12">
        <PurchasesCard v-if="user.profileId !== 30" />
      </CCol>
    </CRow>
    <CRow>
      <CCol xl="6">
        <VolumeBonusCard v-if="user.profileId !== 30" />
      </CCol>
      <CCol xl="6">
        <CompletionCard v-if="user.profileId !== 30" />
      </CCol>
    </CRow>
  </div>
</template>

<script>
const PurchasesCard = () =>
  import(
    /* webpackChunkName: "purchases-card" */ "@/components/organisms/PurchasesCard"
  );
const VolumeBonusCard = () =>
  import(
    /* webpackChunkName: "volume-bonus-card" */ "@/components/organisms/VolumeBonusCard"
  );
const CompletionCard = () =>
  import(
    /* webpackChunkName: "completion-products-card" */ "@/components/organisms/CompletionCard"
  );
const WelcomeCard = () =>
  import(
    /* webpackChunkName: "welcome-card" */ "@/components/organisms/WelcomeCard"
  );
export default {
  name: "Dashboard",
  components: {
    PurchasesCard,
    VolumeBonusCard,
    CompletionCard,
    WelcomeCard,
  },
  computed: {
    lang() {
      return this.$store.getters.getCurrentLang;
    },
    messages() {
      return this.$store.getters.getLangMessages;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },
};
</script>
